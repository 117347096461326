<template>
	<div class="deviceDetails" id="deviceDetails">
		<div class="deviceHeader" :style="{ 'background-image': 'url(' + bgHeader + ')' }">
			<img :src="image" class="userImg" />
			<span class="name textEllipsis">{{ getValue('name') }}</span>
		</div>

		<div class="detalisContent">
			<div :style="contentStyle">
				<div class="detalis">
					<div class="title">基本信息</div>
					<div class="item">
						<span class="itemLabel">型号</span>
						<span class="itemValue">{{ getValue('modelName') }}</span>
					</div>
					<div class="item">
						<span class="itemLabel">品牌</span>
						<span class="itemValue">{{ getValue('brandName') }}</span>
					</div>
					<div class="item">
						<span class="itemLabel">评价</span>
						<span class="itemValue">好</span>
					</div>
					<div class="item">
						<span class="itemLabel">{{$VUEAPPDEVICECODE()}}</span>
						<span class="itemValue">{{ getValue('code') }}</span>
					</div>
					<div class="item">
						<span class="itemLabel">卡片号</span>
						<span class="itemValue">{{ getValue('label') }}</span>
					</div>
					<div class="item">
						<span class="itemLabel">购入时间</span>
						<span class="itemValue">{{ formatDate(getValue('purchaseDate')) }}</span>
					</div>
					<div class="item">
						<span class="itemLabel">原值</span>
						<span class="itemValue">{{ getValue('price') }} 元</span>
					</div>
					<div class="item">
						<span class="itemLabel">计费方式</span>
						<span class="itemValue">{{ $formatBillingType1(getValue('billingType')).name }}</span>
					</div>
					<div class="item">
						<span class="itemLabel">价格</span>
						<span class="itemValue">{{ getValue('sharePrice') }}元</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// let img =  require('../../../../../static/iconImage/reservationListBgHeader.png')

export default {
	name: 'deviceDetail',
	computed: {
		contentStyle() {
			let heightStr = this.$changePxToRem('150px');
			return {
				width: '100%',
				height: `calc(100% - ${heightStr})`,
				overflow: 'scroll'
			};
		},
		image() {
			if (this.$valueIsExist(this.dataInfo, 'photo') && this.dataInfo['photo'] != '') {
				let photo = this.dataInfo['photo'];
				return this.$replacePhoto(`${window.ipConfig.VUE_APP_BASEURL}/api/image/get?id=${this.$getPhotosId(photo)}&scale=0.1`);
			}
			return this.$replacePhoto('');
		}
	},
	props: {},
	watch: {
		loading: {
			handler(value) {
				if (value) {
					this.startLoading('deviceDetails');
				} else {
					if (this.loadingView == null) {
						return;
					}
					this.loadingView.close();
				}
			}
		}
	},
	data() {
		return {
			loading: false,
			loadingView: null,
			showFooter: false,
			dataInfo: {},
			visible: false,
			photo: 'https://img0.baidu.com/it/u=1159755399,1068993229&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
			bgHeader: require('../../../../../../static/iconImage/bgimg_blue.png')
		};
	},
	created() {},
	mounted() {
		this.$nextTick(() => {
			this.getDetail();
		});
	},
	methods: {
		getValue: function(key) {
			if (this.$valueIsExist(this.dataInfo, key)) {
				return this.dataInfo[key];
			}
			return '-';
		},

		getDetail: function() {
			let query = this.$route.query;
			let showFooter = false;
			if (this.$valueIsExist(query, 'showFooter')) {
				showFooter = query['showFooter'];
			}
			this.showFooter = showFooter == 0 ? false : true;
			let id = '';
			if (this.$valueIsExist(query, 'id')) {
				id = query['id'];
			}

			if (id == '') {
				return;
			}

			let getData = () => {
				return new Promise(resolve => {
					let query = {
						deviceId: id
					};
					this.$api.deviceWorkManager
						.shareDeviceDetail(query)
						.then(res => {
							let info = {};
							if (this.$valueIsExist(res, 'data')) {
								info = res['data'];
							}
							resolve({ result: true, info: info });
						})
						.catch(e => {
							resolve({ result: true, info: {} });
						});
				});
			};

			this.loading = true;
			getData().then(res => {
				this.dataInfo = res.info;
				this.loading = false;
			});
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@styles/variables.scss';

.deviceDetails {
	padding-top: 5px;
	width: 100%;
	position: relative;
	height: 100%;

	.deviceHeader {
		width: calc(100% - 20px);
		transform: translate(10px, 0px);
		padding: 14px 0px 14px 0px;
		border-bottom: 1px solid #ccc;
		height: 80px;
		background-size: 100% 100%;
		box-sizing: content-box;
		border-radius: 8px;
		overflow: hidden;

		.userImg {
			width: 80px;
			height: 80px;
			float: left;
			margin-left: 10px;
		}

		.name {
			font-size: 18px;
			color: #fff;
			font-weight: bold;
			float: right;
			width: calc(100% - 100px);
			max-height: 80px;
			position: relative;
			top: 50%;
			transform: translate(0px, -50%);
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}

	.detalisContent {
		width: calc(100% - 20px);
		transform: translate(10px, 0px);
		.detalis {
			background: $background_color2;
			overflow: scroll;
			padding: 5px;
			margin-top: 5px;
			border-radius: 2px;

			.title {
				font-size: 12px;
				color: $color1;
				font-weight: bold;
				padding-top: 10px;
				height: 32px;
			}

			.item {
				min-height: 32px;
				height: 7.65%;
				line-height: 32px;
				border-bottom: 1px solid rgba($color: #000000, $alpha: 0.09);
				font-size: 12px;
				font-weight: 500;

				.itemValue {
					float: right;
					font-size: 12px;
				}

				.itemLabel {
					font-size: 12px;
					display: inline-block;
					padding-left: 4px;
					width: 106px;
					color: $color3;
					text-align: left;
				}
			}
		}
	}

	.deviceFooter {
		position: absolute;
		color: #ffffff;
		font-weight: bold;
		left: 50%;
		transform: translateX(-50%);
		bottom: 20px;
		height: 30px;
		line-height: 3630;
		text-align: center;
		width: 96%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-around;

		.agree {
			border-radius: 5px;
			margin-right: 10px;
			width: 100px;
			height: 30px;
			text-align: center;
			color: white;
			line-height: 30px;
			background-color: $color_primary;
		}

		.disagree {
			border-radius: 5px;
			margin-right: 10px;
			width: 100px;
			height: 30px;
			text-align: center;
			color: white;
			line-height: 30px;
			background-color: orangered;
		}
	}
}
</style>
